import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Link,
  Modal,
  Pagination,
  Table,
  Tag,
  Text,
  Textarea,
  Thumbnail,
  Title,
  Tooltip,
  useToast,
} from '@nimbus-ds/components';
import apiInstance from '../../utils/apiUtils';
import { goTo } from '@tiendanube/nexo/helpers';
import nexo from '../../nexoClient';
import { DataList, EmptyMessage } from '@nimbus-ds/patterns';
import {
  CalendarDaysIcon,
  CheckIcon,
  DrinkIcon,
  ExternalLinkIcon,
  EyeIcon,
  ForbiddenIcon,
} from '@nimbus-ds/icons';
import { IMetaPagination } from '../../lib/interfaces/meta-pagination.interfaces';
import { TableRowSkeleton } from '../../components';

function BriefingsAwaitListPage() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const API_BASE_PATH = `/custom-order-item/list-briefings`;

  interface IDataItem {
    _id: string;
    custom_orders_id: string;
    ns_product_id: number;
    ns_variant_id: number;
    product_name: string;
    product_image: string | null;
    briefing_obj: { [key: string]: string | number | null };
    updatedAt: string;
    days_remaining: number;
    ns_order_id: number;
    ns_order_number: number;
    ns_customer_name: string;
    custom_status_name: string;
  }

  const [loadingDataList, setLoadingDataList] = useState(false);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [modalBriefingOpen, setModalBriefingOpen] = useState(false);
  const [reprovalMessageDanger, setReprovalMessageDanger] = useState(false);
  const [reprovalMensage, setReprovalMessage] = useState<string>('');
  const [dataList, setDataList] = useState<IDataItem[]>([]);
  const [modalItem, setModalItem] = useState<IDataItem | null>(null);
  const [metaPagination, setMetaPagination] = useState<IMetaPagination | null>(
    null,
  );

  useEffect(() => {
    getDataList();
  }, []);

  const handleChangeData = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setReprovalMessage(value);
    return false;
  };

  const getDataList = async (page = 1) => {
    setLoadingDataList(true);
    try {
      const result = await apiInstance.get(`${API_BASE_PATH}?page=${page}`);
      const data = result.data as {
        meta: IMetaPagination;
        result: IDataItem[];
      };
      setMetaPagination(data.meta);
      // setDataList([
      //   {
      //     _id: '65dc9dbcf1e9590a27516edf',
      //     ns_order_id: 1435504470,
      //     ns_customer_name: 'Leonam Bernini',
      //     custom_status_name: 'Pagamento Aprovado',
      //     custom_orders_id: '65dc9dbcf1e9590a27516edd',
      //     ns_product_id: 195704931,
      //     ns_variant_id: 790418923,
      //     product_name: 'Copo de Papel Personalizado 500ml (500)',
      //     product_image:
      //       'https://acdn.mitiendanube.com/stores/002/907/105/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-1024-1024.png',
      //     updatedAt: '2024-02-26T14:21:19.485Z',
      //     days_remaining: 5,
      //     briefing_obj: {
      //       motivo: 'Restaurante',
      //       fundo_embalagem: 'Gradiente',
      //       info_contato: 'Endereço da Loja',
      //       cor_copo: 'Preto e Vermelho',
      //       preferencias: 'Vibrante',
      //       descricao: 'Testando',
      //       Instrucoes: 'Teste',
      //       termos: 'on',
      //       logo: 'https://www.dropbox.com/scl/fi/yroxeo0585u6r57h0wuv5/logo-wod-512x512.png?rlkey=w38kwkrma2l9g8jv7bje1t39l&dl=0',
      //       referencia:
      //         'https://www.dropbox.com/scl/fi/wojp4f90ap5ccop2lzda9/logo-wod-512x512-1.png?rlkey=85jsm1kd4usb39pcnpm3vjc3y&dl=0',
      //     },
      //   },
      // ]);
      setDataList(
        data.result.sort((a, b) => a.days_remaining - b.days_remaining),
      );
    } catch (error) {
      console.log(error);
    }
    setLoadingDataList(false);
  };

  const aprovalBriefing = async (id: string) => {
    setLoadingSaveData(true);
    try {
      await apiInstance.put(`/custom-order-item/${id}/briefing/aproved`);
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: t('BriefingsAwaitListPage.list.modal.messages.aproval.success'),
      });
      setModalBriefingOpen(false);
      setModalItem(null);
      getDataList();
    } catch (error) {
      console.log(error);
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: t('BriefingsAwaitListPage.list.modal.messages.aproval.danger'),
      });
    }
    setLoadingSaveData(false);
  };

  const reaprovalBriefing = async (id: string) => {
    setReprovalMessageDanger(false);
    if (
      reprovalMensage === undefined ||
      reprovalMensage === null ||
      reprovalMensage.trim() === ''
    ) {
      setReprovalMessageDanger(true);
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: t(
          'BriefingsAwaitListPage.list.modal.messages.reproval.message_required',
        ),
      });
      return false;
    }
    setLoadingSaveData(true);
    try {
      await apiInstance.put(`/custom-order-item/${id}/briefing/reproved`, {
        reproved_msg: reprovalMensage,
      });
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: t('BriefingsAwaitListPage.list.modal.messages.reproval.success'),
      });
      setModalBriefingOpen(false);
      setModalItem(null);
      getDataList();
    } catch (error) {
      console.log(error);
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: t('BriefingsAwaitListPage.list.modal.messages.reproval.danger'),
      });
    }
    setLoadingSaveData(false);
  };

  const handleOpenBriefing = (item: IDataItem) => {
    setModalItem(item);
    setModalBriefingOpen(true);
  };

  return (
    <>
      <Box display="grid" gap="4">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="4"
        >
          <Title as="h3">{t('BriefingsAwaitListPage.title')}</Title>
        </Box>
        {dataList.length || loadingDataList ? (
          <>
            <Box display="grid" gap="4">
              <DataList>
                {loadingDataList ? (
                  <DataList.Row gap="1">
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" gap="4" alignItems="center">
                        <Thumbnail.Skeleton width="30px" />
                        <Text.Skeleton />
                      </Box>
                      <Text.Skeleton />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Text.Skeleton />
                      <Text.Skeleton />
                    </Box>
                    <Box display="flex" justifyContent="space-between" gap="2">
                      <Box display="flex" flexWrap="wrap" gap="2" pt="2">
                        <Tag.Skeleton />
                        <Tag.Skeleton />
                      </Box>
                      <IconButton.Skeleton />
                    </Box>
                  </DataList.Row>
                ) : (
                  dataList.map((item, index) => (
                    <DataList.Row key={index} gap="1">
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" gap="4" alignItems="center">
                          {item.product_image !== null &&
                          item.product_image !== '' ? (
                            <Thumbnail
                              src={item.product_image}
                              alt={item.product_name}
                              width="30px"
                            />
                          ) : (
                            <Thumbnail alt={item.product_name} width="30px" />
                          )}
                          <Text>{item.product_name}</Text>
                        </Box>
                        <Box display="flex" gap="2">
                          <Text fontWeight="bold">Prazo:</Text>
                          {/* <Text fontWeight="bold">
                            {formatDate(item.updatedAt, 'numeric', false)}
                          </Text> */}
                          <Tag appearance="warning">
                            <Icon
                              color="currentColor"
                              source={<CalendarDaysIcon />}
                            />
                            {item.days_remaining}{' '}
                            {item.days_remaining > 1 ? `dias` : 'dia'}
                          </Tag>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        gap="5"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box display="grid" gap="2">
                          <Box display="flex" gap="5">
                            <Box display="flex" gap="2">
                              <Text fontWeight="bold">Pedido:</Text>
                              <Tooltip
                                content={t(
                                  'LastsOrdersInProgressListPage.list.table.body.tooltip_open_order',
                                )}
                                position="right"
                              >
                                <Link
                                  as="button"
                                  onClick={() =>
                                    goTo(nexo, `/orders/${item.ns_order_id}`)
                                  }
                                  appearance="primary"
                                  title={`ID: ${item.ns_order_id}`}
                                >
                                  #{item.ns_order_number}
                                </Link>
                              </Tooltip>
                            </Box>
                            <Tag appearance="neutral">
                              {item.custom_status_name}
                            </Tag>
                          </Box>
                          <Box display="flex" gap="2">
                            <Text fontWeight="bold">Cliente</Text>
                            <Text>{item.ns_customer_name}</Text>
                          </Box>
                        </Box>
                        <Tooltip
                          content={t(
                            'BriefingsAwaitListPage.list.table.ctas.show_briefing',
                          )}
                          position="top"
                        >
                          <Button
                            appearance="neutral"
                            disabled={loadingSaveData}
                            onClick={() => {
                              handleOpenBriefing(item);
                            }}
                          >
                            <Icon color="currentColor" source={<EyeIcon />} />
                            Visualizar informações
                          </Button>
                        </Tooltip>
                      </Box>
                    </DataList.Row>
                    // <Table.Row key={index}>
                    //   <Table.Cell>
                    //     <Box display="flex" gap="4" alignItems="center">
                    //       {item.product_image !== null &&
                    //       item.product_image !== '' ? (
                    //         <Thumbnail
                    //           src={item.product_image}
                    //           alt={item.product_name}
                    //           width="30px"
                    //         />
                    //       ) : (
                    //         <Thumbnail alt={item.product_name} width="30px" />
                    //       )}
                    //       <Text>{item.product_name}</Text>
                    //     </Box>
                    //   </Table.Cell>
                    //   <Table.Cell>
                    //     {formatDate(item.updatedAt, 'numeric', false)}
                    //   </Table.Cell>
                    //   <Table.Cell>
                    //     {item.days_remaining}{' '}
                    //     {item.days_remaining > 1 ? `dias` : 'dia'}
                    //   </Table.Cell>
                    //   <Table.Cell>
                    //     <Box
                    //       display="flex"
                    //       gap="2"
                    //       justifyContent="space-between"
                    //     >
                    //       <Tooltip
                    //         content={t(
                    //           'BriefingsAwaitListPage.list.table.ctas.show_briefing',
                    //         )}
                    //         position="top"
                    //       >
                    //         <IconButton
                    //           disabled={loadingSaveData}
                    //           onClick={() => {
                    //             handleOpenBriefing(item);
                    //           }}
                    //           size="1.8rem"
                    //           source={<EyeIcon />}
                    //         />
                    //       </Tooltip>
                    //     </Box>
                    //   </Table.Cell>
                    // </Table.Row>
                  ))
                )}
              </DataList>

              {/* <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Cell as="th">
                      {t('BriefingsAwaitListPage.list.table.title1')}
                    </Table.Cell>
                    <Table.Cell as="th" width="100px">
                      {t('BriefingsAwaitListPage.list.table.title2')}
                    </Table.Cell>
                    <Table.Cell as="th" width="80px">
                      {t('BriefingsAwaitListPage.list.table.title3')}
                    </Table.Cell>
                    <Table.Cell as="th" width="50px">
                      {t('BriefingsAwaitListPage.list.table.title4')}
                    </Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {loadingDataList ? (
                    <TableRowSkeleton rowQty={3} cellQty={4} />
                  ) : (
                    dataList.map((item, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <Box display="flex" gap="4" alignItems="center">
                            {item.product_image !== null &&
                            item.product_image !== '' ? (
                              <Thumbnail
                                src={item.product_image}
                                alt={item.product_name}
                                width="30px"
                              />
                            ) : (
                              <Thumbnail alt={item.product_name} width="30px" />
                            )}
                            <Text>{item.product_name}</Text>
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          {formatDate(item.updatedAt, 'numeric', false)}
                        </Table.Cell>
                        <Table.Cell>
                          {item.days_remaining}{' '}
                          {item.days_remaining > 1 ? `dias` : 'dia'}
                        </Table.Cell>
                        <Table.Cell>
                          <Box
                            display="flex"
                            gap="2"
                            justifyContent="space-between"
                          >
                            <Tooltip
                              content={t(
                                'BriefingsAwaitListPage.list.table.ctas.show_briefing',
                              )}
                              position="top"
                            >
                              <IconButton
                                disabled={loadingSaveData}
                                onClick={() => {
                                  handleOpenBriefing(item);
                                }}
                                size="1.8rem"
                                source={<EyeIcon />}
                              />
                            </Tooltip>
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  )}
                </Table.Body>
              </Table> */}
              {metaPagination !== null ? (
                <Pagination
                  activePage={metaPagination.page}
                  onPageChange={(page) => {
                    getDataList(page);
                  }}
                  pageCount={metaPagination.pages}
                />
              ) : (
                <></>
              )}
            </Box>
          </>
        ) : (
          <Card>
            <Card.Body>
              <EmptyMessage
                title={t('BriefingsAwaitListPage.list.empty.title')}
                text={`${t('BriefingsAwaitListPage.list.empty.text')}`}
                icon={<DrinkIcon size={32} />}
                actions={null}
              />
            </Card.Body>
          </Card>
        )}
      </Box>
      <Modal
        maxWidth={{ xs: '100%', md: '900px' }}
        open={modalBriefingOpen}
        onDismiss={() => {
          setModalBriefingOpen(false);
          setModalItem(null);
        }}
      >
        <Modal.Header
          title={`${t('BriefingsAwaitListPage.list.modal.title')}`}
        />
        <Modal.Body>
          <Box display="grid" gap="6">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Cell as="th" width="130px">
                    {t('BriefingsAwaitListPage.list.modal.table.title1')}
                  </Table.Cell>
                  <Table.Cell as="th">
                    {t('BriefingsAwaitListPage.list.modal.table.title2')}
                  </Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {modalItem !== null ? (
                  <>
                    {Object.entries(modalItem.briefing_obj).map(
                      ([key, value], index) => (
                        <Table.Row key={index}>
                          <Table.Cell>
                            <Text fontWeight="bold">{key}</Text>
                          </Table.Cell>
                          <Table.Cell>
                            {value?.toString().indexOf('http') === 0 ? (
                              <Link
                                appearance="primary"
                                as="a"
                                href={`${value}`}
                                target="_blank"
                              >
                                {value}
                                <Icon
                                  color="primary-interactive"
                                  source={<ExternalLinkIcon />}
                                />
                              </Link>
                            ) : (
                              value
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ),
                    )}
                  </>
                ) : (
                  <TableRowSkeleton rowQty={5} cellQty={2} />
                )}
              </Table.Body>
            </Table>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Box display="flex" justifyContent="flex-end" gap="4" width="100%">
            {modalItem !== null ? (
              <>
                <Box display="flex" gap="4" width="59%">
                  <Textarea
                    appearance={reprovalMessageDanger ? 'danger' : 'neutral'}
                    id="reproved_msg"
                    lines={1}
                    placeholder={`${t(
                      'BriefingsAwaitListPage.list.modal.reproval_message.placeholder',
                    )}`}
                    value={reprovalMensage}
                    onChange={handleChangeData}
                  />
                </Box>
                <Box
                  display="flex"
                  gap="4"
                  width="41%"
                  justifyContent="flex-end"
                >
                  <Button
                    appearance="danger"
                    onClick={() => reaprovalBriefing(modalItem._id)}
                  >
                    <Icon color="currentColor" source={<ForbiddenIcon />} />
                    {t('BriefingsAwaitListPage.list.modal.ctas.reproval')}
                  </Button>
                  <Button
                    appearance="primary"
                    onClick={() => aprovalBriefing(modalItem._id)}
                  >
                    <Icon color="currentColor" source={<CheckIcon />} />
                    {t('BriefingsAwaitListPage.list.modal.ctas.aproval')}
                  </Button>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BriefingsAwaitListPage;
