import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Link,
  Modal,
  Pagination,
  Table,
  Tag,
  Text,
  Textarea,
  Thumbnail,
  Title,
  Tooltip,
  useToast,
} from '@nimbus-ds/components';
import apiInstance from '../../utils/apiUtils';
import { goTo } from '@tiendanube/nexo/helpers';
import nexo from '../../nexoClient';
import { DataList, EmptyMessage } from '@nimbus-ds/patterns';
import {
  CheckIcon,
  DrinkIcon,
  EyeIcon,
  ForbiddenIcon,
  HistoryIcon,
} from '@nimbus-ds/icons';
import { IMetaPagination } from '../../lib/interfaces/meta-pagination.interfaces';

function ItemsAwaitChangeArtListPage() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const API_BASE_PATH = `/custom-order-item/list-art-reproved`;

  interface IDataItem {
    _id: string;
    custom_orders_id: string;
    ns_product_id: number;
    ns_variant_id: number;
    product_name: string;
    product_image: string | null;
    briefing_obj: { [key: string]: string | number | null } | null;
    updatedAt: string;
    count_art_reproved: number;
    ns_order_id: number;
    ns_order_number: number;
    ns_customer_name: string;
    custom_status_name: string;

    art_detail_observation_file: string;
    art_detail_observation_message: string;
    art_detail_updatedAt: string;
    art_detail_status: 'in_review' | 'review_reproved' | 'review_aproved';
    observation_papello: string | null;
  }

  const [loadingDataList, setLoadingDataList] = useState(false);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [modalBriefingOpen, setModalBriefingOpen] = useState(false);
  const [reprovalMessageDanger, setReprovalMessageDanger] = useState(false);
  const [reprovalMensage, setReprovalMessage] = useState<string>('');
  const [dataList, setDataList] = useState<IDataItem[]>([]);
  const [modalItem, setModalItem] = useState<IDataItem | null>(null);
  const [metaPagination, setMetaPagination] = useState<IMetaPagination | null>(
    null,
  );

  useEffect(() => {
    getDataList();
  }, []);

  const handleChangeData = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setReprovalMessage(value);
    return false;
  };

  const getDataList = async (page = 1) => {
    setLoadingDataList(true);
    try {
      const result = await apiInstance.get(`${API_BASE_PATH}?page=${page}`);
      const data = result.data as {
        meta: IMetaPagination;
        result: IDataItem[];
      };
      setMetaPagination(data.meta);
      setDataList(data.result);
      // setDataList([
      //   {
      //     _id: '65b40c250d6f1e53bec0db9d',
      //     ns_order_id: 1426281631,
      //     ns_customer_name: 'Laís Pereira',
      //     custom_status_name: 'Arte em desenvolvimento',
      //     custom_orders_id: '65b40c250d6f1e53bec0db9c',
      //     ns_product_id: 193858129,
      //     ns_variant_id: 781216189,
      //     product_name: 'Copo de Papel Personalizado 180ml (3000)',
      //     product_image:
      //       'https://dcdn.mitiendanube.com/stores/002/907/105/products/copo-de-papel-personalizado-cafe-agua-4-c1f2b657d8a1a85eba17054291122237-1024-1024.png',
      //     updatedAt: '2024-02-28T14:46:32.630Z',
      //     count_art_reproved: 2,
      //     art_detail_observation_file:
      //       'https://www.dropbox.com/scl/fi/241rfyr61zpo0ygpsia16/cassio-1-transformed.png?rlkey=udnk5cxnbnwzfx0dhndmpkbj8&dl=0',
      //     art_detail_observation_message: 'precisa alterar umas coisas',
      //     art_detail_updatedAt: '2024-02-28T14:46:31.293Z',
      //     art_detail_status: 'in_review',
      //     observation_papello: null,
      //     briefing_obj: null,
      //   },
      // ]);
    } catch (error) {
      console.log(error);
    }
    setLoadingDataList(false);
  };

  const aprovalBriefing = async (id: string) => {
    setLoadingSaveData(true);
    try {
      await apiInstance.put(`/custom-order-item/${id}/art-review-approved`);
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: 'Alteração aprovada',
      });
      setModalBriefingOpen(false);
      setModalItem(null);
      getDataList();
    } catch (error) {
      console.log(error);
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: 'Erro ao aprovar alteração',
      });
    }
    setLoadingSaveData(false);
  };

  const reaprovalBriefing = async (id: string) => {
    setReprovalMessageDanger(false);
    if (
      reprovalMensage === undefined ||
      reprovalMensage === null ||
      reprovalMensage.trim() === ''
    ) {
      setReprovalMessageDanger(true);
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: 'Para reprovar a alteração é necessario informar o motivo ao cliente',
      });
      return false;
    }
    setLoadingSaveData(true);
    try {
      await apiInstance.put(`/custom-order-item/${id}/art-review-reproved`, {
        observation_papello: reprovalMensage,
      });
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: 'Ação realizada com sucesso',
      });
      setModalBriefingOpen(false);
      setModalItem(null);
      getDataList();
    } catch (error) {
      console.log(error);
      addToast({
        id: 'aproval' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: 'Erro ao executar a ação',
      });
    }
    setLoadingSaveData(false);
  };

  const handleOpenBriefing = (item: IDataItem) => {
    setModalItem(item);
    setModalBriefingOpen(true);
  };

  return (
    <>
      <Box display="grid" gap="4">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="4"
        >
          <Title as="h3">Solicitações de alteração de arte</Title>
        </Box>
        {dataList.length || loadingDataList ? (
          <>
            <Box display="grid" gap="4">
              <DataList>
                {loadingDataList ? (
                  <DataList.Row gap="1">
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" gap="4" alignItems="center">
                        <Thumbnail.Skeleton width="30px" />
                        <Text.Skeleton />
                      </Box>
                      <Text.Skeleton />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Text.Skeleton />
                      <Text.Skeleton />
                    </Box>
                    <Box display="flex" justifyContent="space-between" gap="2">
                      <Box display="flex" flexWrap="wrap" gap="2" pt="2">
                        <Tag.Skeleton />
                        <Tag.Skeleton />
                      </Box>
                      <IconButton.Skeleton />
                    </Box>
                  </DataList.Row>
                ) : (
                  dataList.map((item, index) => (
                    <DataList.Row key={index} gap="1">
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" gap="4" alignItems="center">
                          {item.product_image !== null &&
                          item.product_image !== '' ? (
                            <Thumbnail
                              src={item.product_image}
                              alt={item.product_name}
                              width="30px"
                            />
                          ) : (
                            <Thumbnail alt={item.product_name} width="30px" />
                          )}
                          <Text>{item.product_name}</Text>
                        </Box>
                        <Box display="flex" gap="2">
                          <Text fontWeight="bold">Alterações:</Text>
                          <Tag appearance="warning">
                            <Icon
                              color="currentColor"
                              source={<HistoryIcon />}
                            />
                            {item.count_art_reproved + 1}ª vez
                          </Tag>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        gap="5"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box display="grid" gap="2">
                          <Box display="flex" gap="5">
                            <Box display="flex" gap="2">
                              <Text fontWeight="bold">Pedido:</Text>
                              <Tooltip
                                content={t(
                                  'LastsOrdersInProgressListPage.list.table.body.tooltip_open_order',
                                )}
                                position="right"
                              >
                                <Link
                                  as="button"
                                  onClick={() =>
                                    goTo(nexo, `/orders/${item.ns_order_id}`)
                                  }
                                  appearance="primary"
                                  title={`ID ${item.ns_order_id}`}
                                >
                                  #{item.ns_order_number}
                                </Link>
                              </Tooltip>
                            </Box>
                            <Tag appearance="neutral">
                              {item.custom_status_name}
                            </Tag>
                          </Box>
                          <Box display="flex" gap="2">
                            <Text fontWeight="bold">Cliente</Text>
                            <Text>{item.ns_customer_name}</Text>
                          </Box>
                        </Box>
                        <Tooltip
                          content={t(
                            'BriefingsAwaitListPage.list.table.ctas.show_briefing',
                          )}
                          position="top"
                        >
                          <Button
                            appearance="neutral"
                            disabled={loadingSaveData}
                            onClick={() => {
                              handleOpenBriefing(item);
                            }}
                          >
                            <Icon color="currentColor" source={<EyeIcon />} />
                            Visualizar informações
                          </Button>
                        </Tooltip>
                      </Box>
                    </DataList.Row>
                  ))
                )}
              </DataList>

              {metaPagination !== null ? (
                <Pagination
                  activePage={metaPagination.page}
                  onPageChange={(page) => {
                    getDataList(page);
                  }}
                  pageCount={metaPagination.pages}
                />
              ) : (
                <></>
              )}
            </Box>
          </>
        ) : (
          <Card>
            <Card.Body>
              <EmptyMessage
                title="Nenhum solicitação de alteração"
                text="No momento nenhum pedido com solicitação para alteração de arte pendente"
                icon={<DrinkIcon size={32} />}
                actions={null}
              />
            </Card.Body>
          </Card>
        )}
      </Box>
      <Modal
        maxWidth={{ xs: '100%', md: '900px' }}
        open={modalBriefingOpen}
        onDismiss={() => {
          setModalBriefingOpen(false);
          setModalItem(null);
        }}
      >
        <Modal.Header title="Observações do cliente para alteração da arte" />
        <Modal.Body padding="none">
          {modalItem !== null ? (
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width="100px">
                    <Text fontWeight="bold">Arquivo:</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      as="a"
                      target="_blank"
                      href={`${modalItem.art_detail_observation_file}`}
                      title="Ver arquivo no dropbox"
                    >
                      {modalItem.art_detail_observation_file}
                    </Link>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="100px">
                    <Text fontWeight="bold">Mensagem:</Text>
                  </Table.Cell>
                  <Table.Cell>
                    {modalItem.art_detail_observation_message}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Box display="flex" justifyContent="flex-end" gap="4" width="100%">
            {modalItem !== null ? (
              <>
                <Box display="flex" gap="4" width="50%">
                  <Textarea
                    appearance={reprovalMessageDanger ? 'danger' : 'neutral'}
                    id="observation_papello"
                    lines={1}
                    placeholder="Motivo da reprovação"
                    value={reprovalMensage}
                    onChange={handleChangeData}
                  />
                </Box>
                <Box
                  display="flex"
                  gap="4"
                  width="50%"
                  justifyContent="flex-end"
                >
                  <Button
                    appearance="danger"
                    onClick={() => reaprovalBriefing(modalItem._id)}
                  >
                    <Icon color="currentColor" source={<ForbiddenIcon />} />
                    Reprovar solicitação
                  </Button>
                  <Button
                    appearance="primary"
                    onClick={() => aprovalBriefing(modalItem._id)}
                  >
                    <Icon color="currentColor" source={<CheckIcon />} />
                    Aprovar solicitação
                  </Button>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ItemsAwaitChangeArtListPage;
