import React, { useEffect, useState } from 'react';

import { t } from 'i18next';
import { Alert, Box, Button, Spinner } from '@nimbus-ds/components';
import apiInstance from '../../utils/apiUtils';

const AppStatusBar: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingActive, setLoadingActive] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    try {
      const result = await apiInstance.get(`/app/status`);
      const data = { ...(result.data as { is_active: boolean }) };

      setActive(data.is_active);
    } catch (error) {
      setActive(false);
    }
    setLoading(false);
  };

  const startStopApp = async () => {
    try {
      setLoadingActive(true);
      const result = await apiInstance.post(
        `/app/${active ? 'stop' : 'start'}`,
      );

      if (result.status === 200) {
        setActive(!active);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingActive(false);
  };

  if (loading) {
    return <></>;
  }

  return (
    <>
      {!active ? (
        <Box marginBottom="5">
          <Alert
            appearance="danger"
            title={`${t('components.AppStatusBar.alert.danger.title')}`}
          >
            {`${t('components.AppStatusBar.alert.danger.message')}`}
            <br />
            {`${t('components.AppStatusBar.alert.danger.message_2')}`}
            <Box display="flex" flexWrap="wrap" gap="2">
              <Button
                disabled={loadingActive}
                onClick={startStopApp}
                appearance="neutral"
              >
                {loadingActive ? (
                  <>
                    <Spinner color="currentColor" size="small" /> Ativando app
                  </>
                ) : (
                  `${t('components.AppStatusBar.alert.danger.cta_text')}`
                )}
              </Button>
            </Box>
          </Alert>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default AppStatusBar;
