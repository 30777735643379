import { Skeleton, Table } from '@nimbus-ds/components';
import React from 'react';

interface TableRowSkeletonProps {
  rowQty: number;
  cellQty: number;
  width?: string | null;
  height?: string | null;
  borderRadius?: string | null;
}

const TableRowSkeleton: React.FC<TableRowSkeletonProps> = ({
  rowQty,
  cellQty,
  width,
  height,
  borderRadius,
}: TableRowSkeletonProps) => {
  return (
    <>
      {Array.from(Array(rowQty).keys()).map((_, rowI) => (
        <Table.Row key={rowI}>
          {Array.from(Array(cellQty).keys()).map((_, cellI) => (
            <Table.Cell key={cellI}>
              <Skeleton
                height={height || '1rem'}
                width={width || '100%'}
                borderRadius={borderRadius || '4'}
              />
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </>
  );
};

export default TableRowSkeleton;
