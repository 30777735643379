import React from 'react';
import { Tooltip } from '@nimbus-ds/components';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ColorPaletteIcon,
  HomeIcon,
  ShoppingCartIcon,
  ToolsIcon,
} from '@nimbus-ds/icons';
import { NavTabs } from '@nimbus-ds/patterns';

const HeaderNavTabs: React.FC = () => {
  const { push } = useHistory();
  const handleGoToConfigs = (page: string) => push(page);

  const { pathname } = useLocation();

  return (
    <NavTabs>
      <NavTabs.Item
        active={pathname === '/'}
        ariaLabel="Home"
        icon={<HomeIcon size="medium" />}
        onClick={() => {
          handleGoToConfigs('/');
        }}
      />
      <NavTabs.Item
        active={pathname === '/custom-orders'}
        title="Gerenciamento dos pedido"
        ariaLabel="Shopping Cart"
        icon={
          <Tooltip content="Gerenciamento dos pedido" position="top">
            <ShoppingCartIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/custom-orders');
        }}
      />
      <NavTabs.Item
        active={pathname === '/custom-orders-items'}
        title="Gerenciamento de itens personalizados"
        ariaLabel="Color palette icon"
        icon={
          <Tooltip
            content="Gerenciamento de itens personalizados"
            position="top"
          >
            <ColorPaletteIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/custom-orders-items');
        }}
      />
      {/* <NavTabs.Item
        active={pathname === '/register/orders/status'}
        title="Gerenciamento dos status dos pedido"
        ariaLabel="Order Status"
        icon={
          <Tooltip content="Gerenciamento dos status dos pedido" position="top">
            <InvoiceIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/register/orders/status');
        }}
      />
      <NavTabs.Item
        active={pathname === '/register/orders/items/status'}
        title="Gerenciamento dos status dos items"
        ariaLabel="Order Status"
        icon={
          <Tooltip content="Gerenciamento dos status dos items" position="top">
            <ColorPaletteIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/register/orders/items/status');
        }}
      /> */}
      {/* <NavTabs.Item
        active={pathname === '/briefings/form'}
        title="Configurações do Briefing"
        ariaLabel="Briefing Config"
        icon={
          <Tooltip
            content="Gerenciamento do formulário de Briefing"
            position="top"
          >
            <ToolsIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/briefings/form');
        }}
      /> */}
      <NavTabs.Item
        active={false}
        title=""
        ariaLabel=""
        icon={null}
        onClick={() => {
          handleGoToConfigs('/');
        }}
      />
    </NavTabs>
  );
};

export default HeaderNavTabs;
