export interface IStoreConfig {
  _id?: string;
  store_id: number;
  erp_token1: string;
  erp_token2: string;
  trello_token1: string;
  trello_token2: string;
  trello_briefing_approved_list: string;
  trello_wait_client_approved_list: string;
  trello_cliente_reproved_list: string;
  trello_cliente_approved_list: string;
  trello_board: string;
  dropbox_token1: string;
  dropbox_token2: string;
  whatsapp_service_token_1: string;
  whatsapp_service_token_2: string;
  qty_art_revision: number;
  notification_whatsapp: boolean;
  notification_email: boolean;
  briefing_owner_whatsapp: string[] | string;
  briefing_owner_email: string[] | string;
  max_width_file_upload: number;
  notification_customer_order_page_header: string;
  notification_customer_order_page_footer: string;
  logo: string;
  name: string;
  store_url: string;
  send_email: string;
  is_active: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export const emptyConfig: IStoreConfig = {
  store_id: 0,
  erp_token1: '',
  erp_token2: '',
  trello_token1: '',
  trello_token2: '',
  dropbox_token1: '',
  dropbox_token2: '',
  trello_briefing_approved_list: '',
  trello_wait_client_approved_list: '',
  trello_cliente_reproved_list: '',
  trello_cliente_approved_list: '',
  trello_board: '',
  whatsapp_service_token_1: 'cf891a1b331c1d4776d72897cf0593b0d1c6f41a',
  whatsapp_service_token_2: '',
  qty_art_revision: 0,
  notification_whatsapp: false,
  notification_email: false,
  briefing_owner_whatsapp: [],
  briefing_owner_email: [],
  max_width_file_upload: 0,
  notification_customer_order_page_header: '',
  notification_customer_order_page_footer: '',
  logo: '',
  name: '',
  store_url: '',
  send_email: '',
  is_active: true,
};
