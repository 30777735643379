export const CustomOrderStatusActionDescription: { [key: string]: string } = {
  order_paid: 'Quando pedido foi pago',
  start_design: 'Quando pedido estiver em desenvolvimento',
  start_production: 'Quando pedido estiver em produção',
  order_fullfiled: 'Quando pedido for despachado',
  order_inroute: 'Quando pedido estiver em transito',
  finished: 'Quando pedido for entregue',
};

export interface ICustomOrderStatus {
  _id?: string;
  store_id: number;
  name: string;
  active: boolean;
  action: string;
  createdAt?: string;
  updatedAt?: string;
}
