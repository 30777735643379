import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Icon,
  Input,
  Link,
  Spinner,
  Tabs,
  Text,
  Title,
  Tooltip,
  useToast,
} from '@nimbus-ds/components';
import {
  getSessionToken,
  getStoreInfo,
  navigateHeader,
} from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import {
  IStoreConfig,
  emptyConfig,
} from '../../lib/interfaces/config.interfaces';
import apiInstance from '../../utils/apiUtils';
import {
  EditIcon,
  ExclamationCircleIcon,
  LockIcon,
  QuestionCircleIcon,
} from '@nimbus-ds/icons';
import { useHistory } from 'react-router-dom';

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { addToast } = useToast();
  const [loadingGetConfig, setLoadingGetConfig] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<IStoreConfig | null>(null);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    getConfig();
    setLoading(false);
  }, []);

  const handleChangeConfig = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...config };
    helpConfig[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setConfig({ ...helpConfig });
    return false;
  };

  const getConfig = async () => {
    try {
      const result = await apiInstance.get(`/store/config`);
      const tempConfig = { ...(result.data as IStoreConfig) };

      tempConfig.briefing_owner_whatsapp = (
        tempConfig.briefing_owner_whatsapp as string[]
      ).join(',');
      tempConfig.briefing_owner_email = (
        tempConfig.briefing_owner_email as string[]
      ).join(',');
      setConfig({ ...tempConfig });
    } catch (error) {
      setConfig({ ...emptyConfig });
    }
    setLoadingGetConfig(false);
  };

  const handleSubmit = async () => {
    try {
      const data = { ...config };
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;

      data.briefing_owner_whatsapp = `${data.briefing_owner_whatsapp}`.split(
        ',',
      );
      data.briefing_owner_email = `${data.briefing_owner_email}`.split(',');

      const result = await apiInstance.post(`/store/config`, data);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: `${t('ConfigsPage.submit_success')}`,
      });
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: `${t('ConfigsPage.submit_danger')}`,
      });
    }
  };

  const handleInstallDropbox = async () => {
    if (config && config.store_id > 0) {
      const token = await getSessionToken(nexo);
      const store = await getStoreInfo(nexo);
      const client_id = process.env.REACT_APP_DROPBOX_CLIENTE_ID;
      const redirect_admin = btoa(
        `${process.env.REACT_APP_NS_APP_ID}#/configs`,
      );
      const api_url = btoa(`${process.env.REACT_APP_API_URI}`);
      const url = `${process.env.REACT_APP_DROPBOX_INSTALL_URL}?token=${token}&lang=${store.country}&client_id=${client_id}&redirect_admin=${redirect_admin}&api_url=${api_url}`;
      if (window.parent) {
        window.parent.location.href = url;
      } else {
        console.error('Não foi possível redirecionar para o site externo.');
      }
    } else {
      addToast({
        id: 'dropbox-token' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: t('app.config.custom-form.page.cards.uploads.redirect_error'),
      });
    }
  };

  const handleInstallTrelloHooks = async () => {
    try {
      const result = await apiInstance.get(`/app/webhook/install`);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: `${t(
          'ConfigsPage.tabs.integrations.cards.trello.cta_alert_success',
        )}`,
      });
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: `${t(
          'ConfigsPage.tabs.integrations.cards.trello.cta_alert_error',
        )}`,
      });
    }
  };

  return (
    <>
      <Page maxWidth="920px">
        <Page.Header
          title={t('ConfigsPage.title')}
          buttonStack={
            <Box display="flex" gap="4">
              <Button
                appearance="neutral"
                onClick={() => {
                  push(`/register/orders/status`);
                }}
              >
                <Icon color="currentColor" source={<EditIcon />} />
                Status dos Pedido
              </Button>
              <Button
                appearance="neutral"
                onClick={() => {
                  push(`/register/orders/items/status`);
                }}
              >
                <Icon color="currentColor" source={<EditIcon />} />
                Status dos Itens
              </Button>
              <Button
                appearance="primary"
                onClick={() => {
                  push(`/briefings/form`);
                }}
              >
                <Icon color="currentColor" source={<EditIcon />} />
                Briefing
              </Button>
            </Box>
          }
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              {loadingGetConfig ? (
                <Box
                  paddingTop="10"
                  paddingBottom="10"
                  display="flex"
                  alignItems="center"
                  gap="4"
                >
                  <Spinner size="large" />
                  <Text>{t('general.loading_text')}</Text>
                </Box>
              ) : (
                <Box display="grid" gap="6">
                  <Tabs fullWidth>
                    <Tabs.Item label={`${t('ConfigsPage.tabs.rules.title')}`}>
                      <Box paddingY="2" display="grid" gap="6">
                        {/* Briefing */}
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.rules.briefing_title',
                            )}`}
                          />
                          <Card.Body>
                            <Box paddingY="2" display="grid" gap="6">
                              <FormField
                                label={
                                  <Tooltip
                                    content={t(
                                      'ConfigsPage.tabs.form.qty_art_revision.label_help_text',
                                    )}
                                    position="right"
                                  >
                                    <Box
                                      display="flex"
                                      gap="2"
                                      alignItems="center"
                                    >
                                      <Text>
                                        {t(
                                          'ConfigsPage.tabs.form.qty_art_revision.label',
                                        )}
                                      </Text>
                                      <Icon
                                        color="currentColor"
                                        source={
                                          <QuestionCircleIcon size="small" />
                                        }
                                      />
                                    </Box>
                                  </Tooltip>
                                }
                                showHelpText={false}
                              >
                                <Input
                                  id="qty_art_revision"
                                  name="qty_art_revision"
                                  type="number"
                                  min="0"
                                  max="10"
                                  step="1"
                                  onChange={handleChangeConfig}
                                  value={config?.qty_art_revision}
                                />
                              </FormField>
                              <FormField
                                label={
                                  <Tooltip
                                    content={t(
                                      'ConfigsPage.tabs.form.max_width_file_upload.label_help_text',
                                    )}
                                    position="right"
                                  >
                                    <Box
                                      display="flex"
                                      gap="2"
                                      alignItems="center"
                                    >
                                      <Text>
                                        {t(
                                          'ConfigsPage.tabs.form.max_width_file_upload.label',
                                        )}
                                      </Text>
                                      <Icon
                                        color="currentColor"
                                        source={
                                          <QuestionCircleIcon size="small" />
                                        }
                                      />
                                    </Box>
                                  </Tooltip>
                                }
                                helpText={`${t(
                                  'ConfigsPage.tabs.form.max_width_file_upload.help_text',
                                )}`}
                                helpIcon={ExclamationCircleIcon}
                              >
                                <Input
                                  append={
                                    <Text color="neutral-textDisabled">MB</Text>
                                  }
                                  appendPosition="end"
                                  id="max_width_file_upload"
                                  name="max_width_file_upload"
                                  type="number"
                                  min="0"
                                  max="10"
                                  step="1"
                                  onChange={handleChangeConfig}
                                  value={config?.max_width_file_upload}
                                />
                              </FormField>
                            </Box>
                          </Card.Body>
                        </Card>
                        {/* Whatsapp */}
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.rules.whatsapp_title',
                            )}`}
                          />
                          <Card.Body>
                            <Box paddingY="2" display="grid" gap="6">
                              <Box display="grid" gap="2">
                                <Text>
                                  {t(
                                    'ConfigsPage.tabs.form.notification_whatsapp.text',
                                  )}
                                </Text>
                                <Checkbox
                                  name="notification_whatsapp"
                                  label={`${t(
                                    'ConfigsPage.tabs.form.notification_whatsapp.label',
                                  )}`}
                                  checked={
                                    config?.notification_whatsapp || false
                                  }
                                  onChange={handleChangeConfig}
                                />
                              </Box>

                              <FormField
                                label={
                                  <Tooltip
                                    content={t(
                                      'ConfigsPage.tabs.form.briefing_owner_whatsapp.label_help_text',
                                    )}
                                    position="right"
                                  >
                                    <Box
                                      display="flex"
                                      gap="2"
                                      alignItems="center"
                                    >
                                      <Text>
                                        {t(
                                          'ConfigsPage.tabs.form.briefing_owner_whatsapp.label',
                                        )}
                                      </Text>
                                      <Icon
                                        color="currentColor"
                                        source={
                                          <QuestionCircleIcon size="small" />
                                        }
                                      />
                                    </Box>
                                  </Tooltip>
                                }
                                showHelpText={true}
                                helpText={`${t(
                                  'ConfigsPage.tabs.form.briefing_owner_whatsapp.help_text',
                                )}`}
                                helpIcon={ExclamationCircleIcon}
                              >
                                <Input
                                  id="briefing_owner_whatsapp"
                                  name="briefing_owner_whatsapp"
                                  onChange={handleChangeConfig}
                                  value={config?.briefing_owner_whatsapp}
                                  placeholder="11999998888,11922223333"
                                />
                              </FormField>
                            </Box>
                          </Card.Body>
                        </Card>
                        {/* E-mail */}
                        <Card>
                          <Card.Header
                            title={`${t('ConfigsPage.tabs.rules.email_title')}`}
                          />
                          <Card.Body>
                            <Box paddingY="2" display="grid" gap="6">
                              <Box display="grid" gap="2">
                                <Text>
                                  {t(
                                    'ConfigsPage.tabs.form.notification_email.text',
                                  )}
                                </Text>
                                <Checkbox
                                  name="notification_email"
                                  label={`${t(
                                    'ConfigsPage.tabs.form.notification_email.label',
                                  )}`}
                                  checked={config?.notification_email || false}
                                  onChange={handleChangeConfig}
                                />
                              </Box>

                              <FormField
                                label={
                                  <Tooltip
                                    content={t(
                                      'ConfigsPage.tabs.form.briefing_owner_email.label_help_text',
                                    )}
                                    position="right"
                                  >
                                    <Box
                                      display="flex"
                                      gap="2"
                                      alignItems="center"
                                    >
                                      <Text>
                                        {t(
                                          'ConfigsPage.tabs.form.briefing_owner_email.label',
                                        )}
                                      </Text>
                                      <Icon
                                        color="currentColor"
                                        source={
                                          <QuestionCircleIcon size="small" />
                                        }
                                      />
                                    </Box>
                                  </Tooltip>
                                }
                                showHelpText={true}
                                helpText={`${t(
                                  'ConfigsPage.tabs.form.briefing_owner_email.help_text',
                                )}`}
                                helpIcon={ExclamationCircleIcon}
                              >
                                <Input
                                  id="briefing_owner_email"
                                  name="briefing_owner_email"
                                  onChange={handleChangeConfig}
                                  value={config?.briefing_owner_email}
                                  placeholder="meu@email.com,br,seu@email.com.br"
                                />
                              </FormField>
                            </Box>
                          </Card.Body>
                        </Card>
                      </Box>
                    </Tabs.Item>
                    <Tabs.Item
                      label={`${t('ConfigsPage.tabs.texts_messages.title')}`}
                    >
                      <Box paddingY="2" display="grid" gap="6">
                        {/* Mensagens pagina de pedidos */}
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.texts_messages.notification_customer_order_page_title',
                            )}`}
                          />
                          <Card.Body>
                            <Box paddingY="2" display="grid" gap="6">
                              <FormField
                                label={t(
                                  'ConfigsPage.tabs.form.notification_customer_order_page_header.label',
                                )}
                                showHelpText={true}
                                helpText={`${t(
                                  'ConfigsPage.tabs.form.notification_customer_order_page_header.help_text',
                                )}`}
                                helpIcon={ExclamationCircleIcon}
                              >
                                <Input
                                  id="notification_customer_order_page_header"
                                  name="notification_customer_order_page_header"
                                  onChange={handleChangeConfig}
                                  value={
                                    config?.notification_customer_order_page_header
                                  }
                                  placeholder=""
                                />
                              </FormField>
                              <FormField
                                label={t(
                                  'ConfigsPage.tabs.form.notification_customer_order_page_footer.label',
                                )}
                                showHelpText={true}
                                helpText={`${t(
                                  'ConfigsPage.tabs.form.notification_customer_order_page_footer.help_text',
                                )}`}
                                helpIcon={ExclamationCircleIcon}
                              >
                                <Input
                                  id="notification_customer_order_page_footer"
                                  name="notification_customer_order_page_footer"
                                  onChange={handleChangeConfig}
                                  value={
                                    config?.notification_customer_order_page_footer
                                  }
                                  placeholder=""
                                />
                              </FormField>
                            </Box>
                          </Card.Body>
                        </Card>
                      </Box>
                    </Tabs.Item>
                    <Tabs.Item label={`${t('ConfigsPage.tabs.colors.title')}`}>
                      <Card>
                        <Card.Header
                          title={`${t(
                            'ConfigsPage.tabs.colors.store_info_title',
                          )}`}
                        />
                        <Card.Body>
                          <Box paddingY="2" display="grid" gap="6">
                            <FormField
                              label={t('ConfigsPage.tabs.form.logo.label')}
                              showHelpText={true}
                              helpText={`${t(
                                'ConfigsPage.tabs.form.logo.help_text',
                              )}`}
                              helpIcon={ExclamationCircleIcon}
                            >
                              <Input
                                append={
                                  <Text color="neutral-textDisabled">
                                    https://
                                  </Text>
                                }
                                id="logo"
                                name="logo"
                                onChange={handleChangeConfig}
                                value={config?.logo}
                              />
                            </FormField>
                            <FormField
                              label={t('ConfigsPage.tabs.form.name.label')}
                              showHelpText={true}
                              helpText={`${t(
                                'ConfigsPage.tabs.form.name.help_text',
                              )}`}
                              helpIcon={ExclamationCircleIcon}
                            >
                              <Input
                                id="name"
                                name="name"
                                onChange={handleChangeConfig}
                                value={config?.name}
                              />
                            </FormField>
                            <FormField
                              label={t('ConfigsPage.tabs.form.store_url.label')}
                              showHelpText={true}
                              helpText={`${t(
                                'ConfigsPage.tabs.form.store_url.help_text',
                              )}`}
                              helpIcon={ExclamationCircleIcon}
                            >
                              <Input
                                append={
                                  <Text color="neutral-textDisabled">
                                    https://
                                  </Text>
                                }
                                id="store_url"
                                name="store_url"
                                onChange={handleChangeConfig}
                                value={config?.store_url}
                              />
                            </FormField>
                            <FormField
                              label={t(
                                'ConfigsPage.tabs.form.send_email.label',
                              )}
                              showHelpText={true}
                              helpText={`${t(
                                'ConfigsPage.tabs.form.send_email.help_text',
                              )}`}
                              helpIcon={ExclamationCircleIcon}
                            >
                              <Input
                                id="send_email"
                                name="send_email"
                                onChange={handleChangeConfig}
                                value={config?.send_email}
                              />
                            </FormField>
                          </Box>
                        </Card.Body>
                      </Card>
                    </Tabs.Item>
                    <Tabs.Item
                      label={`${t('ConfigsPage.tabs.integrations.title')}`}
                    >
                      <Box display="grid" gap="6">
                        {/* WHATSAPP */}
                        <Box display="grid" gap="2">
                          <Title as="h3">
                            {t(
                              'ConfigsPage.tabs.integrations.cards.whatsapp.title',
                            )}
                          </Title>
                          <Card>
                            <Card.Body>
                              <Box display="grid" gap="4">
                                <Text>
                                  {t(
                                    'ConfigsPage.tabs.integrations.cards.whatsapp.description',
                                  )}
                                </Text>
                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.form.whatsapp_service_token_1.label',
                                  )}
                                  showHelpText={false}
                                  helpIcon={ExclamationCircleIcon}
                                  helpText={`${t(
                                    'ConfigsPage.tabs.form.whatsapp_service_token_1.help_text',
                                  )}`}
                                >
                                  <Input
                                    id="whatsapp_service_token_1"
                                    name="whatsapp_service_token_1"
                                    onChange={handleChangeConfig}
                                    value={config?.whatsapp_service_token_1}
                                  />
                                </FormField>
                              </Box>
                            </Card.Body>
                          </Card>
                        </Box>
                        {/* DROPBOX */}
                        <Box display="grid" gap="2">
                          <Title as="h3">
                            {t(
                              'ConfigsPage.tabs.integrations.cards.dropbox.title',
                            )}
                          </Title>
                          <Card>
                            <Card.Body>
                              <Box display="grid" gap="4">
                                <Text>
                                  {t(
                                    'ConfigsPage.tabs.integrations.cards.dropbox.description',
                                  )}
                                </Text>
                                <Button
                                  type="button"
                                  appearance="primary"
                                  onClick={handleInstallDropbox}
                                >
                                  <Icon
                                    color="currentColor"
                                    source={<LockIcon size={12} />}
                                  />
                                  {t(
                                    'ConfigsPage.tabs.integrations.dropbox_cta',
                                  )}
                                </Button>
                              </Box>
                            </Card.Body>
                          </Card>
                        </Box>
                        {/* TRELLO */}
                        <Box display="grid" gap="2">
                          <Title as="h3">
                            {t(
                              'ConfigsPage.tabs.integrations.cards.trello.title',
                            )}
                          </Title>
                          <Card>
                            <Card.Body>
                              <Box display="grid" gap="4">
                                <Text>
                                  {t(
                                    'ConfigsPage.tabs.integrations.cards.trello.description',
                                  )}
                                </Text>

                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.form.trello_token1.label',
                                  )}
                                  showHelpText={false}
                                  helpIcon={ExclamationCircleIcon}
                                  helpText={`${t(
                                    'ConfigsPage.tabs.form.trello_token1.help_text',
                                  )}`}
                                >
                                  <Input
                                    id="trello_token1"
                                    name="trello_token1"
                                    onChange={handleChangeConfig}
                                    value={config?.trello_token1}
                                  />
                                </FormField>
                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.form.trello_token2.label',
                                  )}
                                  showHelpText={false}
                                  helpIcon={ExclamationCircleIcon}
                                  helpText={`${t(
                                    'ConfigsPage.tabs.form.trello_token2.help_text',
                                  )}`}
                                >
                                  <Input
                                    id="trello_token2"
                                    name="trello_token2"
                                    onChange={handleChangeConfig}
                                    value={config?.trello_token2}
                                  />
                                </FormField>
                                <Text>
                                  {t(
                                    'ConfigsPage.tabs.integrations.cards.trello.cta_help_text',
                                  )}
                                </Text>
                                <Button
                                  as="button"
                                  appearance="primary"
                                  onClick={handleInstallTrelloHooks}
                                >
                                  {t(
                                    'ConfigsPage.tabs.integrations.cards.trello.cta',
                                  )}
                                </Button>

                                <br />
                                <Title as="h5">
                                  {t(
                                    'ConfigsPage.tabs.integrations.cards.trello.columns_legend',
                                  )}
                                </Title>
                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.form.trello_board.label',
                                  )}
                                  showHelpText={true}
                                  helpIcon={ExclamationCircleIcon}
                                  helpText={`${t(
                                    'ConfigsPage.tabs.form.trello_board.help_text',
                                  )}`}
                                >
                                  <Input
                                    id="trello_board"
                                    name="trello_board"
                                    onChange={handleChangeConfig}
                                    value={config?.trello_board}
                                  />
                                </FormField>
                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.form.trello_briefing_approved_list.label',
                                  )}
                                  showHelpText={true}
                                  helpIcon={ExclamationCircleIcon}
                                  helpText={`${t(
                                    'ConfigsPage.tabs.form.trello_briefing_approved_list.help_text',
                                  )}`}
                                >
                                  <Input
                                    id="trello_briefing_approved_list"
                                    name="trello_briefing_approved_list"
                                    onChange={handleChangeConfig}
                                    value={
                                      config?.trello_briefing_approved_list
                                    }
                                  />
                                </FormField>
                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.form.trello_cliente_approved_list.label',
                                  )}
                                  showHelpText={true}
                                  helpIcon={ExclamationCircleIcon}
                                  helpText={`${t(
                                    'ConfigsPage.tabs.form.trello_cliente_approved_list.help_text',
                                  )}`}
                                >
                                  <Input
                                    id="trello_cliente_approved_list"
                                    name="trello_cliente_approved_list"
                                    onChange={handleChangeConfig}
                                    value={config?.trello_cliente_approved_list}
                                  />
                                </FormField>
                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.form.trello_wait_client_approved_list.label',
                                  )}
                                  showHelpText={true}
                                  helpIcon={ExclamationCircleIcon}
                                  helpText={`${t(
                                    'ConfigsPage.tabs.form.trello_wait_client_approved_list.help_text',
                                  )}`}
                                >
                                  <Input
                                    id="trello_wait_client_approved_list"
                                    name="trello_wait_client_approved_list"
                                    onChange={handleChangeConfig}
                                    value={
                                      config?.trello_wait_client_approved_list
                                    }
                                  />
                                </FormField>
                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.form.trello_cliente_reproved_list.label',
                                  )}
                                  showHelpText={true}
                                  helpIcon={ExclamationCircleIcon}
                                  helpText={`${t(
                                    'ConfigsPage.tabs.form.trello_cliente_reproved_list.help_text',
                                  )}`}
                                >
                                  <Input
                                    id="trello_cliente_reproved_list"
                                    name="trello_cliente_reproved_list"
                                    onChange={handleChangeConfig}
                                    value={config?.trello_cliente_reproved_list}
                                  />
                                </FormField>
                              </Box>
                            </Card.Body>
                          </Card>
                        </Box>
                      </Box>
                    </Tabs.Item>
                  </Tabs>

                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      disabled={loading}
                      appearance="primary"
                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <Spinner color="currentColor" size="small" />
                      ) : (
                        ''
                      )}
                      {t('general.save')}
                    </Button>
                  </Box>
                </Box>
              )}
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default ConfigsPage;
