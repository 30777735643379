import { useEffect } from 'react';

import { Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import { AppStatusBar, ConfigButton } from '../../components';
import { Box } from '@nimbus-ds/components';
import BriefingsAwaitListPage from './BriefingsAwaitListPage';
import ItemsAwaitChangeArtListPage from './ItemsAwaitChangeArtListPage';

function PageMain() {
  const { t } = useTranslation();

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <>
      <Page>
        <Page.Header
          title={t('MainPage.title')}
          buttonStack={<ConfigButton />}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <AppStatusBar />
            </Layout.Section>
            <Layout.Section>
              <Box display="grid" gap="10">
                <BriefingsAwaitListPage />

                <ItemsAwaitChangeArtListPage />

                {/* Pedidos em andamento
                <LastsOrdersInProgressListPage /> */}
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default PageMain;
